<template>
  <!-- 个人设置（已搁置） -->
  <div class="editPassword_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="account">
      <div class="account_item" @click="$router.push({ name: 'EditPassword' })">
        <div class="item_label">个人信息</div>
        <div class="item_content">
          <span class="icon-right"></span>
        </div>
      </div>
      <div class="account_item" @click="onUpdate">
        <div class="item_label">版本更新</div>
        <div class="item_content ">
          <span class="icon-update update"></span>
        </div>
      </div>
      <div class="account_item" @click="dialVideoTalk">
        <div class="item_label">呼叫指挥中心</div>
        <div class="item_content">
          <span class="icon-dial dial"></span>
        </div>
      </div>
    </div>
    <div class="logout" @click="logout">
      退 出 登 录
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { mapState } from "vuex";
export default {
  data: () => ({}),
  methods: {
    /**
     * 登出操作
     */
    logout() {
      Dialog.confirm({
        title: "系统提示",
        message: "确认退出登录"
      }).then(() => {
        this.$jsBridge.logout();
        this.$router.go(-1);
        this.$router.replace({ name: "Login" });
      });
    },
    onUpdate() {
      Dialog.confirm({
        title: "系统提示",
        message: "确认更新程序"
      }).then(() => {
        this.$jsBridge.updateApp();
      });
    },
    dialVideoTalk() {
      Dialog.confirm({
        title: "系统提示",
        message: "确认发起视频通话"
      }).then(() => {
        this.$jsBridge.callVideoTalk();
      });
    }
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp
    })
  }
};
</script>

<style lang="less" scoped>
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}

.editPassword_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.account {
  flex: 1;
  width: 100%;
  padding: 0 14px;

  .account_item {
    height: 60px;
    border-bottom: 1px solid rgba(7, 17, 27, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

    .item_label {
      color: #333;
    }

    .item_content {
      margin-right: 10px;
      color: #000;

      .update {
        color: skyblue;
      }

      .dial {
        color: green;
      }
    }
  }
}

.logout {
  width: 100%;
  height: 50px;
  background-color: #063390;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
</style>
